import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
    providers: [DatePipe]
})
export class AppComponent implements OnInit{
  	title = 'AppWebster';
   	myDate = new Date();

   	constructor(
    	private metaTagService: Meta,
    	private datePipe: DatePipe
  	) { }

   	ngOnInit() {
    this.metaTagService.addTags([
      { name: 'keywords', content: 'Web development, App development, Web to app' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Digamber Singh' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'date', content: this.datePipe.transform(this.myDate, 'yyyy-MM-dd'), scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' }
    ]);
  }

  	
}
