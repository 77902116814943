import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, Title, Meta } from '@angular/platform-browser';
import { AWConfig } from "../../config/aw.config";

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
	

	site_phone = AWConfig.site_phone;
	site_email = AWConfig.site_email;
	site_address = AWConfig.site_address;
	
  title = 'AppWebster - Mathness game';
  game = 'Mathness';

  	constructor(
      private titleService: Title,
      private metaTagService: Meta,
      private route: ActivatedRoute
    ) {
      this.game = this.route.snapshot.paramMap.get('game') ? this.route.snapshot.paramMap.get('game') : this.game
      this.title = `AppWebster - ${this.game} game`;
    }
  	
  	ngOnInit() {
      // Meta
      
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Build your dreams with AppWebster.' }
    );
  }

}
