import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {NotfoundComponent} from './pages/notfound/notfound.component';
import {PaymentComponent} from './pages/payment/payment.component';
import {PrivacyComponent} from './pages/privacy/privacy.component';

const routes: Routes = [
  {
    path: ``, loadChildren: () =>
      import('./pages/home/home.module').then(m => m.HomeModule),
      canActivate:[]
  },
  {
    path: `contact`, loadChildren: () =>
      import('./pages/contact/contact.module').then(m => m.ContactModule),
      canActivate:[]
  },
  {
    path: `about`, loadChildren: () =>
      import('./pages/about/about.module').then(m => m.AboutModule),
      canActivate:[]
  },
  {
    path: `payment`, loadChildren: () =>
      import('./pages/payment/payment.module').then(m => m.PaymentModule),
      canActivate:[]
  },
  {
    path: `payment/:p`, loadChildren: () =>
      import('./pages/payment/payment.module').then(m => m.PaymentModule),
      canActivate:[]
  },
  {
    path: `memory-game`, loadChildren: () =>
      import('./pages/memory/memory.module').then(m => m.MemoryGameModule),
      canActivate:[]
  },
  // {
  //   path: `privacy`, loadChildren: () =>
  //     import('./pages/privacy/privacy.component').then(m => m.PrivacyComponent),
  //     canActivate:[]
  // },
  {path: 'game-privacy/:game', component: PrivacyComponent},
  {path: 'game-privacy', component: PrivacyComponent},
  {path: '404', component: NotfoundComponent},
  // {path: 'payment', component: PaymentComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled', // Add options right here
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
