<div class="w3l-bootstrap-header fixed-top" id="navbar">
    <nav class="navbar navbar-expand-lg navbar-light p-2">
      <div class="container">
        <a class="navbar-brand" [routerLink]="['']">
          <img src="assets/images/logo.png" class="img-fluid logo" alt="" />
          <span class="site-name">AppWebster</span>
        </a>
        <!-- if logo is image enable this   
      <a class="navbar-brand" href="#index.html">
          <img src="image-path" alt="Your logo" title="Your logo" style="height:35px;" />
      </a> -->
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
  
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
              <a class="nav-link" [routerLink]="['']" [class.active]="router.isActive('/', false)" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }" >Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['about']" [class.active]="router.isActive('/about', false)" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">About</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['contact']" [class.active]="router.isActive('/contact', false)" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">Contact</a>
            </li>
          </ul>
          <!-- <div class="form-inline">
            <a href="#" class="login mr-4">Log in</a>
              <a href="#" class="btn btn-primary btn-theme">Create Free Account</a>
          </div> -->
        </div>
      </div>
    </nav>
  </div>