import { Component, OnInit } from '@angular/core';
import { AWConfig } from "../../config/aw.config";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	site_phone = AWConfig.site_phone;
	site_email = AWConfig.site_email;
	site_address = AWConfig.site_address;
	social_google = AWConfig.social_google;
	social_facebook = AWConfig.social_facebook;
	social_twitter = AWConfig.social_twitter;
  constructor() { }

  ngOnInit(): void {
  }

}
