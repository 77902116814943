export class AWConfig {
  public static host = "http://admin.appwebster.com";
  public static api_token = "67bDUjZ5PJ7MR5WY";

  public static site_phone = "+65 9040 0933";
  public static site_email = "info@appwebster.com";
  public static site_address = "391 Upper Paya Lebar road, 534981, Singapore";

  public static social_google = "https://www.google.com";
  public static social_facebook = "https://www.facebook.com";
  public static social_twitter = "https://www.twitter.com";

  public static currency = 'USD';
  public static packages = [ 
  					{	
  						title: 'Mobile', 
  						fullTitle: 'Mobile Development', 
  						priceOneTime: 0, 
  						priceMonthly: 39, 
  						features: [	
  									{ title: "iOS App", available: 1 },
									{ title: 'Android App', available: 1 },
									{ title: 'Unlimited push notification', available: 1 },
									{ title: 'Unlimited app republish', available: 1 },
									{ title: '24/7 support', available: 1 },
									{ title: 'Own a website', available: 0 },
									{ title: 'Domain & Hosting', available: 0 },
									{ title: 'Maintain & Update website', available: 0 },
									{ title: 'Customizations, Design, Layouts', available: 0 },
									{ title: 'CMS backends', available: 0 },
									{ title: 'Integrations of social network', available: 0 }
  								]
  					},

  					{	
  						title: 'Web & App', 
  						fullTitle: 'Web & App Development', 
  						priceOneTime: 299, 
  						priceMonthly: 89, 
  						features: [	
  									{ title: "Android & iOS App", available: 1 },
									{ title: 'Own a website', available: 1 },
									{ title: '5 desire domain email', available: 1 },
									{ title: 'Unlimited push notification', available: 1 },
									{ title: 'Unlimited app republish', available: 1 },
									{ title: 'Domain & Hosting', available: 1 },
									{ title: 'Maintain App & Website', available: 1 },
									{ title: 'Customizations, Design, Layouts', available: 1 },
									{ title: 'CMS backends', available: 1 },
									{ title: 'Integrations of social network', available: 1 },
									{ title: '24/7 support', available: 1 },
  								]
  					},

  					{	
  						title: 'One off', 
  						fullTitle: 'One off Development', 
  						priceOneTime: 499, 
  						priceMonthly: 0, 
  						features: [	
  									{ title: "iOS App", available: 1 },
									{ title: 'Android App', available: 1 },
									{ title: 'Own a website', available: 1 },
									{ title: 'Domain & Hosting', available: 1 },
									{ title: 'Maintain & Update website', available: 1 },
									{ title: 'Customizations, Design, Layouts', available: 1 },
									{ title: 'CMS backends', available: 1 },
									{ title: 'Integrations of social network', available: 1 },
									{ title: 'Unlimited push notification', available: 0 },
									{ title: 'Unlimited app republish', available: 0 },
									{ title: '24/7 support', available: 0 },
  								]
  					},

  				]
}
