import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  	constructor(private elementRef:ElementRef, public router: Router) { }

	ngOnInit(): void {
	}

	ngAfterViewInit() {
		// document.querySelectorAll('.some-class').forEach(item => {

	 //  		this.elementRef.nativeElement.querySelector('#navbarSupportedContent > ul > li').addEventListener('click', this.onClick.bind(this));
	 //  	})
	  	this.elementRef.nativeElement.querySelectorAll('#navbarSupportedContent>ul>li.nav-item').forEach(item => {
  			item.addEventListener('click', this.onClick.bind(this));
		})
	}

	onClick(targetElement: string) {
		var element = document.getElementsByClassName("navbar-toggler")[0];
		// console.log(element)
  		element.classList.add("collapsed");

		var element1 = document.getElementById("navbarSupportedContent");
		// console.log(element1)
  		element1.classList.remove("show");
	  	// console.log('You clicked on', targetElement);
	}

	

    scroll(el: HTMLElement) {
        el.scrollIntoView();
    }
}
